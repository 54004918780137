<template>
  <div :class="['hover-overlay', rootClasses]" @click="handleClick">
    <span class="hover-overlay__content">
      {{ $t(`cases.itemOverlay.${type}.content`) }}
      <span class="hover-overlay__content-highlight">{{ $t(`cases.itemOverlay.${type}.highlight`) }}</span>
    </span>
  </div>
</template>

<script lang="ts" setup>
import type { IHoverOverlayEmits, IHoverOverlayProps } from './HoverOverlay.types';

const props = defineProps<IHoverOverlayProps>();
const { type, isShown } = toRefs(props);

const emit = defineEmits<IHoverOverlayEmits>();

const rootClasses = computed(() => ({
  [`hover-overlay--${type.value}`]: true,
  'hover-overlay--shown': isShown.value,
}));

const handleClick = () => {
  if (type.value === 'type3') {
    emit('toFaq');
    return;
  }

  emit('toSteam');
};
</script>

<style lang="scss" scoped src="./HoverOverlay.scss" />
