<template>
  <div
    ref="item"
    class="shared-kit-drop-item"
    :class="[props.disabled && 'shared-kit-drop-item--disabled']"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
    @touchend="handleTouchEnd"
  >
    <SharedKitDropItemHoverOverlay
      v-if="!noOverlay"
      :type="hoverType"
      :is-shown="isShowOverlay"
      @to-steam="handleSteam"
      @to-faq="handleFaq"
    />
    <div class="shared-kit-drop-item__background">
      <NuxtImg :src="props.image" class="background__image" :quality="80" />
      <div class="background__bg" />
    </div>
    <div v-if="props.shining" class="shared-kit-drop-item__shinner" />
    <div class="shared-kit-drop-item__top-left">
      <div class="top-left__price">
        <span>{{ price }}</span>
        <UserController>
          <template #default="{ isFake }">
            <SharedPrettyCurrency :currency="currency" :is-fake="isFake" />
          </template>
        </UserController>
      </div>
      <div v-if="type === 3" class="shared-kit-drop-item__steam-icon">
        <SvgoSquareSteamIcon />
      </div>
      <slot name="top-left" />
    </div>
    <div class="shared-kit-drop-item__top-right">
      <slot name="top-right" />
    </div>
    <div class="shared-kit-drop-item__bottom">
      <div class="bottom__name">{{ props.name }}</div>
    </div>
    <slot name="buttons" />
  </div>
</template>

<script setup lang="ts">
import type { IPropsSharedKitDropItem } from '~/components/SharedKitDropItem/SharedKitDropItem.types';
import { createSteamMarketLink, SteamMarketIds } from '~/utils/steam.utils';

const {
  $projectConfig: {
    defaultCurrency,
    faq: {
      type3: { categoryId, questionId },
    },
  },
} = useNuxtApp();

const OPEN_OPTIONS = { target: '_blank' };

const props = defineProps<IPropsSharedKitDropItem>();
const { id, name, type } = toRefs(props);

const item = ref<HTMLElement | null>(null);

const isHovered = ref(false);
const isMobileClicked = ref(false);

const currency = computed(() => props.currency || defaultCurrency);

const isShowOverlay = computed(() => !props.noOverlay && isHovered.value);

const hoverType = computed(() => {
  if (type.value === 3) return 'type3';
  return 'default';
});

onMounted(() => {
  if (!item.value) return;
  useClickOutside(item.value, () => (isHovered.value = false), false);
});

const handleSteam = () => {
  if (needToSkipMobileClick()) return;

  return navigateTo(createSteamMarketLink(SteamMarketIds.DOTA, name.value, false), {
    external: true,
    open: OPEN_OPTIONS,
  });
};

const handleFaq = () => {
  if (needToSkipMobileClick()) return;

  const localeRoute = useLocaleRoute();
  const parsedRoute = localeRoute(ROUTING.FAQ.MAIN);

  if (!parsedRoute) return;

  return navigateTo(
    {
      path: parsedRoute.path,
      query: {
        category_id: categoryId,
        question_id: questionId,
      },
    },
    {
      open: OPEN_OPTIONS,
    },
  );
};

const handleTouchEnd = () => {
  if (isHovered.value || isMobileClicked.value) return;
  isHovered.value = true;
  isMobileClicked.value = true;
};

const needToSkipMobileClick = () => {
  if (isMobileClicked.value) {
    isMobileClicked.value = false;
    return true;
  }

  return false;
};

defineExpose({
  el: item,
  id: id?.value,
});
</script>

<style scoped lang="scss">
.shared-kit-drop-item {
  --main-color: v-bind('props.color');
  --shining-color: v-bind('props.shiningColor');
}
</style>

<style scoped lang="scss" src="./SharedKitDropItem.scss" />
